@import '__importable.scss';
$bfNavHeight: 6.6rem;

.product-view {
	position: relative;
	z-index: $zindex-1;

	&[data-use-padding='true'] {
		padding-top: 0.6rem;
	}

	@media screen and (min-width: $media-md-lg) {
		height: calc(100vh - $bfNavHeight);

		&[data-use-padding='true'] {
			padding-top: 4.8rem;
		}

		&[data-use-height-tops-step='true']{
			height: auto;
		}
	}

	@media screen and (min-width: $media-lg) and (max-width: $media-xxl) {
		&[data-use-height-tops-step='true']{
			height: auto;
		}
	}

	@media screen and (width >=$max-width) {
		&[data-use-padding='true'] {
			padding-top: 1.6rem;
		}
	}

	&.--no-preview {
		padding-left: 0.8rem;
		h6{
			margin: 0;
			font-size: 2rem;
		}
		button{
			padding: 0;
			font-size: 1.2rem;
		}
		[class*="TopFrameEducation"] {
			display: inherit !important;
		}
	}

	&.--bf-filtering {
		padding-top: 0;

		[class*="Heading"] {
			margin-block: 0.8rem;
		}

		.image-container {
			padding-top: 0;
			top: -1.6rem;
			display: flex;
			justify-content: center;
		}

		.product-image,
		.frame-preview {
			top: unset;
		}

		@media screen and (width < $media-xsm) {
			.image-container {
				height: 160px;
				top: 0;
			}

			.product-image,
			.frame-preview {
				width: 100%;
				height: auto;
				max-height: unset;
			}
		}

		@media screen and (width > $media-xsm) {

			.product-image,
			.frame-preview {
				height: auto;
				top: unset;
			}
		}

		@media screen and (width <=$media-lg-max) {
			[class*="TopFrameEducation"] {
				display: none;
			}
		}
	}

	.image-container {
		position: relative;
		padding-top: 0.6rem;
		width: 100%;
		display: flex;
		justify-content: center;

		@media screen and (max-width: $media-sm) {
			padding-top: 1.6rem;
		}

		@media screen and (max-width: $media-md-lg) {
			display: grid;
			grid-template-areas: 'stack';
			align-items: flex-end;

			>* {
				grid-area: stack;
			}
		}
	}

	.product-image,
	.frame-preview {
		top: -1.6rem;
		padding-inline: 1.6rem;

		@media screen and (max-width: $media-sm) and (min-height: $media-lg) {
			max-height: 160px;
		}

		@media screen and (max-width: $media-lg) and (min-height: $media-md) {
			max-height: 220px;
		}
	}

	.product-image {
		height: auto;
		width: auto;
		position: relative;
		mix-blend-mode: multiply;
		z-index: 1;
		padding-inline: 1.6rem;
	}

	.frame-preview {
		position: absolute;
		transform: rotate(-30deg) translate(-75%, -250%);
		transition: transform 500ms cubic-bezier(0.67, 0.77, 0.19, 0.97);
		will-change: transform;
		z-index: 2;

		&.is-active {
			transform: rotate(0deg) translate(0, 0);
			transition: transform 500ms cubic-bezier(0.23, 0.77, 0.19, 0.97);
			will-change: transform;

			@media screen and (width < $media-md) {
				padding-top: 0;
			}
		}

		@media (prefers-reduced-motion) {
			transition: transform none;

			&.is-active {
				transition: transform none;
			}
		}

		@media only screen and (min-device-width: 76.8em) and (max-device-width: 102.4em) and (-webkit-min-device-pixel-ratio: 2) {
			transform: rotate(-30deg) translate(-50%, -275%);
		}
	}

	@media screen and (min-height: $iphone-13-width) and (max-height: $iphone-13-height) and (-webkit-min-device-pixel-ratio: 3) {
		padding-top: 1.6rem;
		padding-bottom: 0.8rem;

		.image-container,
		.product-image,
		.frame-preview {
			height: 12rem;
		}
	}

	@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
		padding-top: 1.6rem;
		padding-bottom: 0.8rem;

		.image-container,
		.product-image,
		.frame-preview {
			max-height: 21rem;
		}
	}
	.no-preview{
		align-items: flex-start;
    	gap: 0.4rem;
		.preview-header {
			justify-content: start;
		}
		padding-bottom: 0.4rem;
	}
}

.my-tops {
	display: none;
	overflow-y: auto;

	>.header {
		flex-basis: 5.6rem;
	}

	>.body {
		flex-basis: 100%;
	}

	@media screen and (min-width: $media-md-lg) {
		flex-basis: content;
		display: flex;

		>.header {
			padding: 1.8rem;
		}
	}
}

.mobile-trigger {
	width: 64px;
	height: 40px;
	position: absolute;
	top: 0;
	right: 1.6rem;
	padding: 0.2rem;
	background-color: $gray-1;
	border: 1px dashed $gray-2;
	border-radius: $radius-2;
	z-index: $zindex-1;

	&.is-active {
		border-style: solid;
		border-color: $gray-2;
	}

	@media screen and (min-width: $media-md-lg) {
		display: none;
	}
	@media screen and (min-width: $media-md-max) {
		top: 1.6rem;
	}
}

.mobile-image {
	height: auto;
	width: 100%;
	position: relative;
	mix-blend-mode: multiply;
}

.tops-counter {
	border-radius: $radius-round;
	width: 2.1rem;
	height: 2rem;
	color: $gray-0;
	background-color: $gray-3;
	position: absolute;
	top: -0.7rem;
	right: -2.4rem;

	&.is-active {
		background-color: $blue-2;
	}
}

.tops-previewing {
	color: $gray-0;
	background-color: $blue-2;
	padding: 0.4rem 0.8rem;
	animation: fadeInLeft 300ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
	border-radius: 0.4rem;
	margin-left: 0.8rem;

	span {
		font-size: 1.08rem;
	}
}

.mobile-count {
	@extend .tops-counter;
	top: -0.8rem;
	right: 0.5rem;
	overflow: hidden;
	z-index: $zindex-2;

	@media screen and (min-width: $media-md-lg) {
		display: none;
		overflow-y: scroll;
	}
}

.header {
	background-color: $gray-0;
	height: 5.6rem;
	border-radius: $radius-3 $radius-3 0 0;
	border-bottom: 1px solid $gray-2;
	position: relative;
	left: -1.6rem;
	top: -2.4rem;

	@media screen and (min-width: $media-lg) {
		width: 100%;
		border: 1px solid $gray-2;
		position: unset;
	}

	>div {
		position: relative;
	}
}

.body {
	border: 1px solid $gray-2;
	max-height: 24rem;
	padding: 1.6rem;
	border-top: 0;
	overflow-y: scroll;
	border-radius: 0 0 $radius-3 $radius-3;
}

.carousel-container {
	position: relative;
	width: 100%;
	border: 1px solid $gray-2;

	.carousel-header {
		position: absolute;
		top: 0.8rem;
		left: 0.8rem;
		z-index: $zindex-1;
		text-shadow: -1px 1px 0px $gray-1;
	}

	.image-carousel {
		padding: 0;
		aspect-ratio: 3/2;

		@media screen and (width >=$media-xl) {
			aspect-ratio: 4/3;
		}
	}

	.educational {
		position: absolute;
		bottom: 0.8rem;
		left: unset;
		right: 0.8rem;
		z-index: $zindex-1;
		max-height: 2.4rem;
		width: fit-content;

		@media screen and (width >=$mq-small) {
			left: 0.8rem;
		}
	}

	@media screen and (width >=$mq-small) {
		border-radius: unset;
		border: none;
	}
}
