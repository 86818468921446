@import '__importable.scss';
.microcard {
	height: 8rem;
	border: 1px solid $gray-2;
	background-color: $gray-0;
	border-radius: 0.4rem;

	[class*="Caption"] {
		padding-right: 0.8rem;
	}
}

.image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	background-color: $gray-1;
	width: 7.2rem;
	height: inherit;
	aspect-ratio: 7.2 / 8;
	position: relative;

	img {
		object-fit: contain;
		mix-blend-mode: multiply;
		width: 100%;
		background-color: $gray-1;

		&[data-type="ACCESSORY"] {
			height: 100%;
			object-fit: cover;
			border-radius: 0;
			clip-path: none !important;
		}

		&[data-type="Gift Card"] {
			padding: 10%;
		}
	}
	&-isQuickAdd {
		img {
			background-color: transparent;
			mix-blend-mode: multiply;
		}
	}
}

.price-with-strikethrough {
	color: $red-1 !important;
}

.compare-price {
	color: $gray-3;
	font-size: 0.8rem;
	font-weight: 500;
	line-height: 1.04rem;
	text-decoration: line-through;
	align-content: center;
}
